<template>
  <VCard actions-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage :color="color" :name="name" class="mr-2" />

        <div>
          <div class="uppercase font-light text-xs">
            {{ $t("app.lessons") }}
          </div>

          <div class="font-medium">
            {{ name }}
          </div>
        </div>
      </div>
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <div class="version col-span-2">
          {{ $t("app.versions") }} {{ version }}
        </div>

        <div v-if="details" class="col-span-2">
          {{ details }}
        </div>

        <VLine :label="$t('app.type')">
          <template #value>
            <div class="flex items-center">
              <div class="lesson-type">
                {{ type }}
              </div>
            </div>
          </template>
        </VLine>

        <VLine :label="$t('app.duration')">
          <template #value>
            <div class="lowercase">
              {{ durationValue }}
            </div>
          </template>
        </VLine>

        <VLine :label="$t('app.available_from')">
          <template #value>
            <div>
              <VDate :date="available_from" />
            </div>
          </template>
        </VLine>

        <VLine :label="$t('app.available_until')">
          <template #value>
            <div>
              <VDate :date="available_until" />
            </div>
          </template>
        </VLine>
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import VImage from "@/components/VImage";
import VDate from "@/components/VDate";

export default {
  components: {
    VDate,
    VCard,
    VLine,
    VImage
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();

    // Computed
    const lesson = computed(() => props.resource);
    const name = computed(() => getText(lesson.value?.texts, "name"));
    const details = computed(() => getText(lesson.value?.texts, "details"));
    const color = computed(() => lesson.value?.colour);
    const version = computed(() => lesson.value?.version);
    const type = computed(() => lesson.value?.type);
    const duration = computed(() => lesson.value?.duration);
    const durationType = computed(() => lesson.value?.duration_type);
    const available_until = computed(() => lesson.value?.available_until ?? "");
    const available_from = computed(() => lesson.value?.available_from ?? "");
    const durationValue = computed(() => {
      return duration.value && durationType.value
        ? `${duration.value} ${t(`app.${durationType.value}`)}`
        : "";
    });

    return {
      name,
      color,
      version,
      available_until,
      available_from,
      details,
      durationValue,
      type
    };
  }
};
</script>

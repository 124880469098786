<template>
  <div>
    <VCard actions-class="bg-theme-5">
      <template #title>
        <div class="flex items-center">
          <VImage :color="color" :name="name" class="mr-2" />

          <div>
            <div class="uppercase font-light text-xs">
              {{ $t("app.evaluations") }}
            </div>

            <div class="font-medium">
              {{ name }}
            </div>
          </div>
        </div>
      </template>

      <template #content>
        <div class="grid grid-cols-2 gap-2">
          <div class="version col-span-2">
            {{ $t("app.versions") }} {{ resource?.version }}
          </div>

          <div v-if="details" class="col-span-2">
            {{ details }}
          </div>

          <VLine :label="$t('app.type')">
            <template #value>
              <div class="flex items-center">
                <div class="lesson-type">
                  {{ type }}
                </div>
              </div>
            </template>
          </VLine>

          <VLine v-if="!isObservation" :label="$t('app.duration')">
            <template #value>
              <div class="lowercase">
                {{ durationValue }}
              </div>
            </template>
          </VLine>

          <div v-else />

          <VLine :label="$t('app.passing_grade')">
            <template #value>
              <div class="flex items-center">
                <div class="lesson-type success">{{ passing_grade }}%</div>
              </div>
            </template>
          </VLine>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import VImage from "@/components/VImage";

export default {
  components: {
    VCard,
    VLine,
    VImage
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  emits: ["refresh"],
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const evaluation = computed(() => props.resource);
    const name = computed(() => getText(evaluation.value?.texts, "name"));
    const details = computed(() => getText(evaluation.value?.texts, "details"));
    const color = computed(() => evaluation.value?.colour);
    const type = computed(() => evaluation.value?.type);
    const passing_grade = computed(() => evaluation.value?.passing_grade);
    const duration = computed(() => evaluation.value?.duration);
    const durationType = computed(() => evaluation.value?.duration_type);
    const durationValue = computed(() => {
      return duration.value && durationType.value
        ? `${duration.value} ${t(`app.${durationType.value}`)}`
        : "";
    });
    const isObservation = computed(() => {
      return evaluation.value?.type === ALL_OPTIONS.OBSERVATION.value;
    });

    return {
      isObservation,
      name,
      passing_grade,
      type,
      color,
      details,
      durationValue
    };
  }
};
</script>

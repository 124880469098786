<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.trainings", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.learning_trainings") }}
        </VHint>
      </VTitle>

      <VButton
        v-if="!data?.registration_status"
        class="btn-primary"
        :label="$t('app.register_now')"
        @click="showModal"
      />
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div>
        <TrainingHeader :training="data" is-view />

        <Versions
          v-if="isInstructor"
          class="mt-8"
          :route="route"
          :versions="data?.training?.versions"
        />
      </div>

      <div class="col-span-2">
        <div
          v-for="(item, index) in data?.outline"
          :key="item?.id"
          :class="{ 'mt-8': index !== 0 }"
        >
          <component
            :is="getComponent(item)"
            :resource="item"
            :evaluation-type="getEvaluationType(item)"
            hide-actions
          />
        </div>
      </div>
    </div>

    <FormModal
      v-if="isModalVisible"
      :id="id"
      size="modal-lg"
      :is-loading="isRegistering"
      @click:cancel="hideModal"
      @click:confirm="onClickRegister"
    >
      <template #header>
        {{ $t("app.register") }}
      </template>

      <template #content>
        <div class="mb-2">
          {{ $t("app.confirm_password_msg") }}
        </div>

        <VText
          v-model="v.password.$model"
          type="password"
          :placeholder="placeholder"
          :errors="v.password.$errors"
          @keyup.enter="onClickRegister"
        />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "@/store";
import { required } from "@/i18n/i18n-validators";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useTraining from "@/composables/useTraining";
import useRequest from "@/composables/useRequest";
import useVerifyPassword from "@/composables/useVerifyPassword";
import useModal from "@/composables/useModal";
import useForm from "@/composables/useForm";
import useTexts from "@/composables/useTexts";
// Components
import VTitle from "@/components/VTitle";
import VButton from "@/components/VButton";
import Versions from "@/components/templates/Versions";
import TrainingHeader from "./TrainingHeader";
import VText from "@/components/inputs/VText";
import FormModal from "@/components/modals/FormModal";
import LessonCard from "./LessonCard";
import AttestationCard from "./AttestationCard";
import EvaluationCard from "./EvaluationCard";
import VHint from "@/components/VHint";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VTitle,
    VHint,
    TrainingHeader,
    Versions,
    VButton,
    LessonCard,
    AttestationCard,
    EvaluationCard,
    VText,
    FormModal
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props, context) {
    // Misc
    const store = useStore();
    const { t } = useI18n();

    // Data
    const data = ref({});
    const isLoading = ref(false);
    const isRegistering = ref(false);
    const value = ref({ password: "" });
    const rules = ref({ password: { required } });

    // Composables
    const { request } = useRequest();
    const { route } = useTraining();
    const { ALL_OPTIONS } = useOptions();
    const { placeholder, verify } = useVerifyPassword();
    const { isModalVisible, showModal, hideModal } = useModal(`#${props.id}`);
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });
    const { getText } = useTexts();

    // Computed
    const isInstructor = computed(() => store.getters["user/isInstructor"]);

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.trainings", 2)} - ${t("app.learning")}`;

      const name = getText(data?.value?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.trainings.read",
        pathParams: { id: props.id }
      });

      data.value = response?.payload?.data ?? {};

      isLoading.value = false;
    };

    const getComponent = item => {
      const components = {
        Lesson: "LessonCard",
        Attestation: "AttestationCard",
        Evaluation: "EvaluationCard"
      };

      return components[item?.outline_object_type] ?? "";
    };

    const getEvaluationType = item => {
      const types = {
        [ALL_OPTIONS.QUIZ.value]: evaluationTypes.QUIZ,
        [ALL_OPTIONS.OBSERVATION.value]: evaluationTypes.OBSERVATION
      };

      return types[item?.type] ?? "";
    };

    const getResource = item => {
      const resourceTypes = {
        Lesson: "lesson",
        Attestation: "attestation",
        Evaluation: "evaluation"
      };
      const resourceType = resourceTypes[item?.outline_object_type] ?? "";

      return {
        [resourceType]: item
      };
    };

    const onClickRegister = async () => {
      isRegistering.value = true;

      const data = await onSave();

      if (!data) {
        isRegistering.value = false;
        return;
      }

      const isVerified = await verify(data.password);

      if (!isVerified) {
        isRegistering.value = false;
        return;
      }

      await register();

      value.value.password = "";
      await v.value.$reset();
      hideModal();

      isRegistering.value = false;
    };

    const register = async () => {
      await request({
        endpoint: "learning.trainings.request",
        pathParams: { id: props.id }
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      isLoading,
      isRegistering,
      data,
      isInstructor,
      getComponent,
      getEvaluationType,
      getResource,
      onClickRegister,
      // useTraining
      route,
      // useVerifyPassword
      placeholder,
      // useModal
      isModalVisible,
      showModal,
      hideModal,
      // useForm
      v,
      documentTitle
    };
  }
};
</script>

<template>
  <VCard actions-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage :color="color" :name="name" class="mr-2" />

        <div>
          <div class="uppercase font-light text-xs">
            {{ $t("app.attestations") }}
          </div>

          <div class="font-medium">
            {{ name }}
          </div>
        </div>
      </div>
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <VLine :label="$t('app.submit_by')" :value="submit_by" />

        <VLine :label="$t('app.approve_by')" :value="approve_by" />

        <VLine
          :label="$t('app.maximum_failed_attempts')"
          :value="maximum_failed_attempts"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useRequest from "@/composables/useRequest";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VImage from "@/components/VImage";
import VLine from "@/components/VLine";

export default {
  components: {
    VCard,
    VImage,
    VLine
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();
    const { request } = useRequest();
    const { ALL_OPTIONS } = useOptions();

    // Data
    const submitter = ref({});
    const approver = ref({});

    // Computed
    const attestation = computed(() => props.resource);
    const trainingAttestation = computed(() => attestation.value?.pivot);
    const name = computed(() => getText(attestation.value?.texts, "name"));
    const color = computed(() => attestation.value?.color);
    const approver_id = computed(() => trainingAttestation.value?.approver_id);
    const submit_by = computed(() => {
      const submitBy = trainingAttestation.value?.submit_by;

      if (submitBy !== ALL_OPTIONS.INSTRUCTOR.value) {
        return t(`app.${submitBy}`);
      }

      const { firstname, lastname } = submitter.value ?? {};
      return firstname && lastname ? `${firstname} ${lastname}` : "-";
    });
    const approve_by = computed(() => {
      const approveBy = trainingAttestation.value?.approve_by;

      if (approveBy !== ALL_OPTIONS.INSTRUCTOR.value) {
        return t(`app.${approveBy}`);
      }

      const { firstname, lastname } = approver.value ?? {};
      return firstname && lastname ? `${firstname} ${lastname}` : "-";
    });
    const submitter_id = computed(() => {
      return trainingAttestation.value?.submitter_id;
    });
    const maximum_failed_attempts = computed(() => {
      return trainingAttestation.value?.maximum_failed_attempts;
    });

    // Methods
    const getSubmitByData = async () => {
      submitter.value = await getUserData(submitter_id.value);
    };

    const getApproveByData = async () => {
      approver.value = await getUserData(approver_id.value);
    };

    const getUserData = async id => {
      if (!id) {
        return;
      }

      const response = await request({
        endpoint: "administration.users.read",
        pathParams: { id },
        showToaster: false
      });

      return response?.payload?.data[0] ?? {};
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getSubmitByData();
      await getApproveByData();
    });

    return {
      name,
      color,
      submit_by,
      approve_by,
      maximum_failed_attempts
    };
  }
};
</script>
